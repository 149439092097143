var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[(_vm.brand.id)?_c('h4',{staticClass:"text-center font-medium-5"},[_vm._v(" "+_vm._s(_vm.$t(_vm.brand.name))+" ")]):_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-skeleton',{staticStyle:{"border-radius":"5px"},attrs:{"width":"100px","height":"20px"}})],1),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.productTableColumn,"table-config-options":{
      endpoint: (_vm.MODULE_NAME + "/getInThisBrandProducts"),
      data: {
        f: {
          brand: _vm.id
        }
      }
    }},scopedSlots:_vm._u([{key:"cell(name)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell(brand)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getValue(data, 'brand') ? _vm.getValue(data, 'brand').name : '---')+" ")]}},{key:"cell(category)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getValue(data, 'categories') ? _vm.getValue(data, 'categories').map(function (brand) { return brand.name; }).join(' , ') : '---')+" ")]}},{key:"cell(model)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.getValue(data, 'detail') ? _vm.getValue(data, 'detail').model : '---')+" ")]}},{key:"cell(status)",fn:function(ref){
    var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.status === null ? '---' : data.item.status ? 'Active' : 'Inactive')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }