<template>
  <div class="pb-2">
    <h4
      v-if="brand.id"
      class="text-center font-medium-5"
    >
      {{ $t(brand.name) }}
    </h4>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-skeleton
        width="100px"
        height="20px"
        style="border-radius: 5px"
      />
    </div>
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="productTableColumn"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getInThisBrandProducts`,
        data: {
          f: {
            brand: id
          }
        }
      }"
    >
      <template #cell(name)="{ data }">
        {{ data.value }}
      </template>
      <template #cell(brand)="{ data }">
        {{ getValue(data, 'brand') ? getValue(data, 'brand').name : '---' }}
      </template>
      <template #cell(category)="{ data }">
        {{ getValue(data, 'categories') ? getValue(data, 'categories').map(brand => brand.name).join(' , ') : '---' }}
      </template>
      <template #cell(model)="{ data }">
        {{ getValue(data, 'detail') ? getValue(data, 'detail').model : '---' }}
      </template>
      <template #cell(status)="{ data }">
        {{ data.item.status === null ? '---' : data.item.status ? 'Active' : 'Inactive' }}
      </template>
    </l-table>
  </div>
</template>
<script>
import {BSkeleton, VBTooltip} from 'bootstrap-vue'
import LTable from '@/views/components/LTable/LTable.vue'
import tableConfig from '@/views/settings/catalog/brands/brandConfig'

export default {
  name: 'ProductsListFilteredByBrand',
  components: { LTable, BSkeleton },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  computed: {
    brand() {
      return this.$store.state[this.MODULE_NAME].brand
    },
  },
  created() {
    this.$store.dispatch(`${this.MODULE_NAME}/get`, this.$route.params?.id)
  },
  methods: {
    getValue(data, tableColumnName) {
      return data.item[tableColumnName] ?? {}
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    remove(data) {
      this.confirmNotification(this, data, `${this.MODULE_NAME}/del`).then(() => {
        this.refetchData()
      })
    },
    update(data) {
      this.$router.push({ name: 'settings-catalog-brand-update', params: { id: data.id } })
    },
  },
  setup() {
    const MODULE_NAME = 'brand'
    const PRODUCT_MODULE_NAME = 'products'
    const { productTableColumn } = tableConfig()
    return {
      productTableColumn,
      MODULE_NAME,
      PRODUCT_MODULE_NAME,
    }
  },
}
</script>

<style scoped lang="scss">

</style>
